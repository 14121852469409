// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// load fonts
WebFont.load({
    google: { // add &display=swap to end of ONLY last font family
        families: ['Roboto:400,500,700','Poppins:400,500,600,700&display=swap']
    },
    //,
    // typekit: {
    // id: 'XXXX'
    // },
    active: function() { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true 
    }
});

// Load BS
require('bootstrap');

//Load Libraries
// require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('objectFitPolyfill/dist/objectFitPolyfill.min');
require('select2/dist/js/select2.full.min');
require('slick-slider/slick/slick.min');

//Load Modules
require('./modules/accordion');
require('./modules/animatein');
// require('./modules/bannerVideo');
require('./modules/formInputs');
// require('./modules/localVideoBlock');
require('./modules/menu');
// require('./modules/modal');
// require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
// require('./modules/sideMenu');
// require('./modules/tabs');




$(function() {
    themeButtonJs();

    animateInEffects();

    menuScroll();
})


// js for generic button
function themeButtonJs() {
    // for mouse movement
    $('.button').on('mouseenter', function (e) {
        var fromTop = $(this).offset().top;
        var fromLeft = $(this).offset().left;
        var thisHeight = $(this).outerHeight();
        var thisWidth = $(this).outerWidth() * 2 + thisHeight + 'px'; // plus the button height to get it right over the curve
        // position of mouse  x and y, flipped to positive
        var posY = (fromTop - e.pageY) * -1;
        var posX = (fromLeft - e.pageX) * -1;
        $(this).find('.button-inner').css({
            'top': posY + "px",
            'left':posX + "px",
            'height': thisWidth,
            'width': thisWidth,
        });
    }).on('mouseleave', function() {
        $(this).find('.button-inner').css({'height':'0','width':'0'});
    });
    // for focus states
    $('.button').on('focus', function (e) {
        var fromTop = $(this).offset().top;
        var fromLeft = $(this).offset().left;
        var thisHeight = $(this).outerHeight();
        var thisWidth = $(this).outerWidth() * 2 + thisHeight + 'px'; // plus the button height to get it right over the curve
        // position of mouse  x and y, flipped to positive
        var posY = (fromTop - e.pageY) * -1;
        var posX = (fromLeft - e.pageX) * -1;
        $(this).find('.button-inner').css({
            'top': "0px",
            'left': "0px",
            'height': thisWidth,
            'width': thisWidth,
        });
    }).on('blur', function() {
        $(this).find('.button-inner').css({'height':'0','width':'0'});
    });
};

function menuScroll() {
    if (window.innerWidth > 992) {
        var headerEl = $('.header');
        var mainEl = $('#main');
        var headerHeight = headerEl.outerHeight();
        if ($(window).scrollTop() > headerHeight) {
            headerEl.addClass('__sticky');
            $('.mainEl').css('padding-top', headerHeight + 'px');
        }
        // on scroll
        $(window).on("scroll", function() {
            if ($(window).scrollTop() > headerHeight) {
                headerEl.addClass('__sticky');
                $('.mainEl').css('padding-top', headerHeight + 'px');
            } else {
                $('.mainEl').css('padding-top', 0);
            }
        });
    }
}


// defining function globally, as in webpack it gets trapped here once compiled
window.animateInEffects = function() {
    $('.__animate').animateIn({
        offset: 70,
        modifier: '__animatein'
    });

    $('.__animateleft').animateIn({
        offset: 0,
        modifier: '__animatein'
    });

    $('.__animateright').animateIn({
        offset: 0,
        modifier: '__animatein'
    });
    $('.__animatefade').animateIn({
        offset: 0,
        modifier: '__animatein'
    });

    $('.__animatenow').animateIn({
        offset: 0,
        modifier: '__animatein'
    });
};