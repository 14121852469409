/* searchtoggle */
$(function() {
    var searchToggle = $('.searchToggleTrigger');
    var searchToggleCloseText = $('.searchToggleCloseText');
    var searchBar = $('.searchBarElement');
    var searchBarFocusElement = $('.searchBarFocus');
    var wrapper = $('.wrapper');
    var searchBarHidden = true;
    var searchHeight = searchBar.outerHeight() + 'px';

    searchToggle.click(function(event) {
        event.preventDefault();
        window.scrollTo(0, 0);
        searchBarHidden = !searchBarHidden;
        $(this).toggleClass('__active');
        searchBar.attr('aria-hidden',searchBarHidden);
        if (searchBarHidden) {
            searchBar.find('*[tabindex=0]').attr('tabindex', '-1');
            searchToggle.focus();
            wrapper.css('transform', 'translateY(0)');
            searchToggleCloseText.css('opacity', 0);
        } else {
            searchBar.find('*[tabindex=-1]').attr('tabindex', '0');
            searchBarFocusElement.focus();
            wrapper.css('transform', 'translateY(' + searchHeight + ')');
            searchToggleCloseText.css('opacity', 1);
        }
    });
});
